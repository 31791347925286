<template>
    <section id="hero">
        <base-jumbotron
                :src="src"
        >
            <v-container
                    fill-height
            >
                <v-row
                        align="center"
                        justify="center"
                >
                        <base-title>
                          {{$t('sponsors.sponsors')}}
                        </base-title>
                </v-row>
            </v-container>
        </base-jumbotron>
    </section>
</template>

<script>
    export default {
        name:'Hero',
        data: () => ({
          src: require('@/assets/img/karl_berlin.jpg')

        })
    }
</script>
